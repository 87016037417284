<template>
  <div class="m-product-options-group">
    <div @click="toggleList" role="button" class="m-product-options-group__toggler">
      <div>{{ $t('Choose your variant') }}</div>
      <SfIcon
        class="m-product-options-group__toggler-chevron"
        :icon="isListVisible ? 'chevron_down' : 'chevron_up'"
      />
    </div>
    <SfGroupedProduct v-if="isListVisible" :has-carousel="false">
      <MGroupedProductItem
        v-for="(product, index) in products"
        :key="product.id"
        v-model="product.qty"
        :title="product.name"
        :qty="product.qty"
        class="sf-grouped-product-item"
        :error-message="quantityMessage(product, index)"
      >
        <template #image>
          <div class="m-product-options-group__left-panel">
            <div class="m-product-title">
              {{ product.name }}
            </div>
            <AProductPrice v-if="showPrice" class="sf-grouped-product-item__price" :product="product" />
          </div>
        </template>
        <template #details>
          <div class="m-product-options-group__product-details" />
        </template>
        <template #title>
          <div class="m-product-options-group__product-details" />
        </template>
        <template #price>
          <div />
        </template>
        <template #input="{ qty }">
          <AQuantitySelector
            :qty="qty"
            :min-qty="0"
            :disabled-decrease="qty < 1"
            :max-qty="product.stock.qty"
            class="sf-grouped-product-item__quantity-selector"
            aria-label="Quantity"
            @input="updateQty(product, index, $event)"
            @max-qty-hit="onMaxQtyHit(index)"
          />
        </template>
      </MGroupedProductItem>
    </SfGroupedProduct>
  </div>
</template>

<script>
import Vue from 'vue'
import config from 'config';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfGroupedProduct from '@storefront-ui/vue/src/components/organisms/SfGroupedProduct/SfGroupedProduct.vue';
import MGroupedProductItem from '~/theme/components/molecules/m-grouped-product-item';
import AQuantitySelector from 'theme/components/atoms/a-quantity-selector';
import sortBy from 'lodash-es/sortBy'
import { isInStock } from '@vue-storefront/core/modules/catalog/helpers/stock'

export default {
  name: 'MProductOptionsGroup',
  components: {
    SfGroupedProduct,
    MGroupedProductItem,
    AQuantitySelector,
    SfIcon
  },
  props: {
    productOptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isListVisible: true,
      showPrice: false,
      productsWithMessage: []
    }
  },
  mounted () {
    this.productsWithMessage = this.products.map(() => false)
  },
  computed: {
    products () {
      const products = Object.values(this.productOptions)
        .map(productOption => {
          const product = productOption.product || {}
          product.qty = isInStock(product) ? productOption.default_qty : 0
          return product
        })
        .filter(product => product?.stock?.is_in_stock)
      return sortBy(products, ['name'])
    },
    imageSize () {
      const width = config.products.thumbnails.width;
      const height = config.products.thumbnails.height;
      return { width, height }
    },
    getImage () {
      return product => this.getThumbnail(product.image, this.imageSize.width, this.imageSize.height)
    }
  },
  methods: {
    toggleList () {
      this.isListVisible = !this.isListVisible
    },
    updateQty (product, index, qty) {
      if (product.stock.qty > qty) {
        Vue.set(this.productsWithMessage, index, false)
      }
      product.qty = qty
    },
    onMaxQtyHit (index) {
      Vue.set(this.productsWithMessage, index, true)
    },
    quantityMessage (product, index) {
      if (!this.productsWithMessage[index]) return ''
      return this.$t('Only {quantity} left', { quantity: product.stock.qty })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";

  .m-product-title {
    font-size: var(--font-size--sm);
  }
  .m-product-options-group {
    margin: 0 var(--spacer-sm);
    @include for-desktop {
      margin: 0;
    }
    &__product-details {
      display: none;
    }
    &__toggler {
      display: flex;
      justify-content: space-between;
      font-weight: var(--font-weight--bold);
      margin-top: var(--spacer-sm);
      cursor: pointer;
    }
    &__toggler-chevron {
      font-size: var(--font-size--base);
    }
  }

  .m-product-options-group ::v-deep {
    .sf-grouped-product-item {
      display: flex;
      padding: 0;
      &__price {
        --grouped-product-item-price-margin: var(--spacer-xs) 0 0 auto;
        --price-special-font-size: var(--font-size--sm);
        --price-regular-font-size: var(--font-size--sm);
        --price-regular-font-weight: var(--font-weight--normal);
      }
      &__aside {
        min-width: 75%;
        padding: var(--spacer-xs) 0;
      }
      &__left-panel {
        @include for-desktop {
          width: 100%;
          max-width: 27.5rem;
        }
      }
      &__description, &__info {
        display: none;
      }
      &__quantity-selector  {
        --input-font-size: var(--font-size--base);
      --quantity-selector-height: 1.15rem;
      --quantity-selector-width: 5.85rem;
      --quantity-selector-background: transparent;
      background-color: transparent;
      margin-right: calc(var(--spacer-xs) * -1.5);
      padding: 0 0 var(--spacer-xs) var(--spacer-sm);
      align-items: baseline;
      top: calc(var(--spacer-xs) * 1.2);
      position: absolute;

      }
    }
  }
  .sku {
    color: #a3a5ad;
  }
</style>
